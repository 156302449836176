import React from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

import { SmallPlayer } from '../components/Player'

const Article = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${rhythm(0.5)};

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const Player = styled(SmallPlayer)`
  margin-right: ${rhythm(0.5)};
`

const StyledLink = styled(Link)`
  box-shadow: none;
  color: inherit;
  text-decoration: none;
`

const ArticleTitle = styled.h3`
  margin-bottom: 0;
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        description={siteDescription}
      >
        <SEO title="Radiote" keywords={[`podcast`, `Steve`, `Merouan`]} />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <ArticleTitle>
                <StyledLink to={node.fields.slug}>{title}</StyledLink>
              </ArticleTitle>
              <small>{node.frontmatter.date}</small>
              <Article>
                <Player src={node.frontmatter.audio_url} />
                <p>{node.frontmatter.summary}</p>
              </Article>
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr-fr")
            title
            summary
            audio_url
          }
        }
      }
    }
  }
`
